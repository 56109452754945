import { BehaviorSubject, firstValueFrom, Observable, of } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { finalize, map, shareReplay } from 'rxjs/operators';
import { APIResponseStatus, APIErrorResponseStatus } from '@app/constants/api-response-status.enum';
import { CredentialsService } from '@app/modules/authentication/credentials.service';

export interface IBaseStoreOptions<T> {
  fetch?: () => Observable<T>;
  onSuccess?: (data: T) => void;
  onError?: (error: any) => void;
  onEmpty?: () => void;
  baseUrl?: string;
  getUrl?: string;
  deleteUrl?: string;
  createUrl?: string;
  updateUrl?: string;
}

export class BaseStatus {
  statusSubject = new BehaviorSubject<APIResponseStatus>(APIResponseStatus.LOADING);
  status$ = this.statusSubject.asObservable();
  get status() {
    return this.statusSubject.value;
  }
  set status(status: APIResponseStatus) {
    this.statusSubject.next(status);
  }
  errorStatusSubject = new BehaviorSubject<APIErrorResponseStatus>(APIErrorResponseStatus.UNKNOWN);
  errorStatus$ = this.errorStatusSubject.asObservable();
  get errorStatus() {
    return this.errorStatusSubject.value;
  }
  set errorStatus(errorStatus: APIErrorResponseStatus) {
    this.errorStatusSubject.next(errorStatus);
  }
}

export class BaseStore<T> extends BaseStatus {
  dataSubject = new BehaviorSubject<T | null>(null);
  data$ = this.dataSubject.asObservable();
  get baseUrl() {
    return this.options?.baseUrl ?? '/org/merchant';
  }
  get data() {
    // to get by value
    const value = this.dataSubject.value;
    return JSON.parse(JSON.stringify(value));
  }
  set data(data: T) {
    this.dataSubject.next(data);
  }

  initiated = false;

  constructor(
    protected httpClient: HttpClient,
    protected credentialsService: CredentialsService,
    protected options?: IBaseStoreOptions<T>
  ) {
    super();
  }

  async init(): Promise<void> {
    if (this.initiated) {
      return;
    }

    await firstValueFrom(this.loadData());
    // this.getData();
     this.initiated = true;
   }

  refresh(): void {
    this.getData();
  }

  getData(): void {
    this.status = APIResponseStatus.LOADING;
    this.loadData().subscribe( {
      next: (data: T) => this.onGetSuccess(data),
      error: (error) => this.errorHandler(error)
    }
    );
  }

  onGetSuccess(data: T): void {
    if (this.options?.onSuccess) {
      this.options.onSuccess(data);
    }
    if ((data instanceof Array && (data as any[]).length === 0) || data === null) {
      this.status = APIResponseStatus.EMPTY;
      return;
    }
    this.data = data;
    this.status = APIResponseStatus.SUCCESS;
  }

  errorHandler(error: any): void {
    if (this.options?.onError) {
      this.options.onError(error);
    }
    this.status = APIResponseStatus.ERROR;
    switch ((error as HttpErrorResponse)?.status) {
      case 400:
        this.errorStatus = APIErrorResponseStatus.BAD_REQUEST;
        break;
      case 401:
        this.errorStatus = APIErrorResponseStatus.UNAUTHORIZED;
        break;
      case 403:
        this.errorStatus = APIErrorResponseStatus.FORBIDDEN;
        break;
      case 404:
        this.errorStatus = APIErrorResponseStatus.NOT_FOUND;
        break;
      case 409:
        this.errorStatus = APIErrorResponseStatus.CONFLICT;
        break;
      case 500:
        this.errorStatus = APIErrorResponseStatus.INTERNAL_SERVER_ERROR;
        break;
      case 503:
        this.errorStatus = APIErrorResponseStatus.SERVICE_UNAVAILABLE;
        break;
      case 504:
        this.errorStatus = APIErrorResponseStatus.GATEWAY_TIMEOUT;
        break;
      case 0:
        this.errorStatus = APIErrorResponseStatus.NO_INTERNET;
        break;
      default:
        this.errorStatus = APIErrorResponseStatus.UNAUTHORIZED;
    }
    if ((error as HttpErrorResponse)?.status === 401) {
      this.status = APIResponseStatus.NOT_FOUND;
    }
    console.error(error);
  }

  getStoredData(): Observable<T> {
    if (this.status === APIResponseStatus.SUCCESS) {
      return of(this.data);
    }
    return this.loadData();
  }

  private loadDataCache$: Observable<T> | null = null;

loadData(): Observable<T> {
 
  // Use cached data if available
  if (!this.loadDataCache$) {
    const { fetch, getUrl } = this.options ?? {};
    this.loadDataCache$ = (fetch
      ? fetch()
      : this.httpClient.get<T>(`${this.baseUrl}${getUrl}`, {
          headers: this.credentialsService.credentials?.token &&
            !getUrl?.includes("cities")
            ? new HttpHeaders({
                Authorization: `Bearer ${this.credentialsService.credentials?.token}`,
              })
            : undefined,
        })
    ).pipe(
      shareReplay(1), // Cache last value for all subscribers
      finalize(() => {
        // Clear cache on completion or error
        this.loadDataCache$ = null;
      })
    );
  }

  return this.loadDataCache$;
}


  loadDataAnonymously(): Observable<T> {
    const { fetch, getUrl } = this.options ?? {};
    if (fetch) {
      return fetch();
    }
    return this.httpClient
      .get<T>(`${this.baseUrl}${getUrl}`, {
        headers: new HttpHeaders(),
      })
      .pipe(shareReplay());
  }
}
