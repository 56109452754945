// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.

import { citiesResponse } from "src/mocked-responses/iot-squared-cities-response";

// The list of file replacements can be found in `angular.json`.
const project = require('../../package.json');

export const environment = {
  logo: "apple-touch-icon.png",
  production: false,
  hmr: false,
  environmentName: 'demo',
  defaultCountryCode: 'sa',
  countrySettings: {
    country: 'UAE',
    countryCode: '+971',
    defaultLatitude: 25.2048,
    defaultLongitude: 55.2708,
    currency: 'AED',
    vehicleNumber: {
      placeholder: 'E.g. 0000-AAA',
      regex: /[a-zA-Z0-9-]*$/i,
    },
  },
  sasToken:
  'sp=r&st=2023-11-14T06:17:32Z&se=2024-11-12T14:17:32Z&spr=https&sv=2022-11-02&sr=c&sig=MVJ%2BxbDqwqKz31xM%2Bp59YmJ5TiMK3Q6uycKwJizF82I%3D',
  citySettings: {
    mockCitiesApi: true,
    mockCitiesApiResponse: citiesResponse,
  },
  company: {
    name: location?.hostname?.split('.')[0] ?? 'COMET',
  },
  version: 'Comet Beta',
  signupURL: 'https://get.devcomet.net/auth/signup',
  signalRUrl: 'https://dev-comet-vendorfunction.azurewebsites.net/api/',
  baseApiUrl: 'https://dev-comet-apis.azure-api.net',
  routeTrackerUrl: 'https://route-tracker-api-integration.azurewebsites.net',
  eventsStoreUrl: 'https://dev-comet-apis.azure-api.net',
  apiUrl: 'https://dev-comet-apis.azure-api.net/vendor/',
  driverAPIUrl: 'https://dev-comet-apis.azure-api.net/driver/',
  authAuthApi: 'https://dev-comet-apis.azure-api.net/uaeauth',
  profileUrl: 'https://dev-comet-apis.azure-api.net/profile',
  ahoyAuthUrl: 'https://devahoy.b2clogin.com',
  transactionsUrl: 'https://dev-comet-apis.azure-api.net/transaction',
  merchantUrl: 'https://dev-comet-apis.azure-api.net/merchant',
  checkoutPublicKey: 'pk_test_40aae7fb-7ff8-4536-9547-87d5aabf78ab',
  here_maps_key: 'xZwPJLh-cdNe9xEslxQrTeDIHsHFIeo7WnJra4S-i_s',
  activeDirectoryUrl:
    'devahoy.onmicrosoft.com/oauth2/v2.0/token?p=B2C_1_MobileSignIn',
  activeDirectoryClientId: '129cf170-113d-4225-a152-6cde2b9c7e8c',
  activeDirectoryScope:
    'openid 129cf170-113d-4225-a152-6cde2b9c7e8c offline_access',
  forgetPasswordLink: '/auth/reset-password',

  featuresAvailability: {
    registration: true,
    resetPassword: true,
    beacons: false,
    domainValidation: true,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
